













































































































































































































































































































































































.examinationHallDataTs {
    color: red;
}

.hasPR /deep/.el-input__inner {
    padding-right: 45px;
}

/deep/.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: '';
}
